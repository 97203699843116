
import { OpenMultiQuestionActions, QuestionAnswer } from '@reasonWithMe/type'
import _ from 'lodash'
import { useMemo } from 'react'

interface ComponentProps {
    enableValidation: boolean
    ids:{
        activeOptionId: string,
        questionId: string,
        answerIndex: number
    }
    obj:{
        question: QuestionAnswer & {
            id: string;
        };
    }
    answer: {
        hasAnswer: boolean;
        actualValue: any;
    }
    formsDispatch: React.Dispatch<OpenMultiQuestionActions>
}

const Open = ({
    enableValidation,
    ids,
    obj,
    answer,
    formsDispatch
}: ComponentProps) => {
    // useEffect(() => {
    //     console.log('input box newly rendered because of key change')
    // }, [])

    const input = useMemo(() => {
        const questionAnswers = obj.question?.answerQuestions
            ?.[ids.answerIndex].questionAnswers || []
        // const answerValue = questionInterface.currentReasonWithMeResponse.answerValue

        return <div>
            <input
                className={[
                    'form-control form-control-sm',
                    enableValidation
                        ? answer.hasAnswer === false
                            ? 'border-danger'
                            : ''
                        : ''
                ].join(' ')}
                placeholder={_.has(questionAnswers[0], 'answerName')
                    ? questionAnswers[0]?.answerName
                    : ''}
                // errors={_.inRange(answerValue, lower, upper) === false}
                // we have to stick to value now because when switching buttons
                // the text doesn't change because this component is still used.
                // for all buttons.
                defaultValue={answer.actualValue}
                onChange={(e) => {
                    formsDispatch({
                        type: 'UPDATE_ANSWER_VALUE',
                        ids,
                        answer: {
                            // this is a text anyway so the condition changed
                            hasAnswer: e.target.value !== '',
                            actualValue: e.target.value
                        }
                    })
                }}
                onBlur={(e) => {
                    formsDispatch({
                        type: 'UPDATE_ANSWER_VALUE',
                        ids,
                        answer: {
                            // this is a text anyway so the condition changed
                            hasAnswer: e.target.value !== '',
                            actualValue: e.target.value
                        }
                    })
                }}
            />
        </div>
    }, [obj, answer, enableValidation])

    return <div className={'col px-4'}>
        <div className={'card multi-question-card'}>
            <div className={'card-body'}>
                {
                    obj.question?.answerQuestions?.[ids.answerIndex].questionTitle
                        ? <h5>
                            {
                                obj.question?.answerQuestions?.[ids.answerIndex].questionTitle
                            }
                        </h5>
                        : ''
                }

                {
                    obj.question?.answerQuestions?.[ids.answerIndex].questionDescription
                        ? <span className={'mb-2'}>
                            {
                                obj.question?.answerQuestions?.[ids.answerIndex].questionDescription
                            }
                        </span>
                        : ''
                }
                {input}
            </div>
        </div>
    </div>
}

export default Open
