
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'
import Checkbox from '@reasonWithMe/components/types/open-list/answerTypes/Checkbox'
import InputList from '@reasonWithMe/components/types/open-list/answerTypes/InputList'
import MultiList from '@reasonWithMe/components/types/open-list/answerTypes/MultiList'
import Open from '@reasonWithMe/components/types/open-list/answerTypes/Open'
import Radio from '@reasonWithMe/components/types/open-list/answerTypes/Radio'
import { setAnswerValue } from '@reasonWithMe/slice'
import {
    OpenListActions,
    OpenListValues,
    OpenMultiQuestionOption,
    QuestionHeader,
    QuestionInterfaceActions,
    ReasonWithMeState
} from '@reasonWithMe/type'
import produce from 'immer'
import _ from 'lodash'
import { useEffect, useMemo, useReducer, useState } from 'react'
import { v4 as uuidV4 } from 'uuid'

interface ComponentProps {
    questionInterface: ReasonWithMeState['questionInterface'],
    componentDispatch?: React.Dispatch<QuestionInterfaceActions>,
    enableTruncation: boolean
    setEnableTruncation: React.Dispatch<React.SetStateAction<boolean>>
}

// multi-list is also included here so it should be ok
export interface BodyMapPreviousQuestion {
    acqName?: string
    acqValue?: string
    acqCoords?: { x: number, y: number }[]
    acqPosition?: string,
    acqPositionString?: string,

}

export interface OpenListPreviousQuestions {
    id: string;
    inputs: {
        options: {
            answerValue: string | number;
            answerName: string;
            answerType: string;
            answerEditable: boolean;
        };
        answerValue: string | boolean | string[];
    }[];
}

type AnswerTypes = 'input' | 'radio' | 'checkbox' | 'input-list' | 'input-list-split' | 'multi-list'

const OpenList = ({
    questionInterface,
    componentDispatch,
    enableTruncation,
    setEnableTruncation
}: ComponentProps) => {
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)

    const [questionHeaders, setQuestionHeaders] = useState<QuestionHeader[]>([])
    const [previousAnswers, setPreviousAnswers] = useState<any[]>([])

    const [formsState, formsDispatch] = useReducer((
        state: OpenListValues[],
        action: OpenListActions
    ) => {
        switch (action.type) {
            case 'SET_INITIAL_VALUES':
                return produce(state, draft => {
                    draft.splice(0, draft.length, ...(action.arr || []))
                })
            case 'ADD_FORM':{
                return produce(state, draft => {
                    draft.push({
                        id: uuidV4(),
                        inputs: _.map(questionHeaders, (o) => {
                            const answerType = o.answerType as AnswerTypes

                            let userTypedAnswer: string | string[] | boolean = ''

                            if (answerType === 'input') {
                                userTypedAnswer = ''
                            } else if (answerType === 'radio') {
                                userTypedAnswer = false
                            } else if (answerType === 'checkbox') {
                                userTypedAnswer = false
                            } else if (answerType === 'input-list') {
                                userTypedAnswer = ['']
                            }

                            const answerValue = o.answerValue as number

                            return {
                                answerValue,
                                answerName: o.answerName || '',
                                answerType,
                                answerEditable: o.answerEditable || true,
                                userTypedAnswer
                            }
                        })
                    })
                })
            }
            case 'DELETE_FORM':{
                return produce(state, draft => {
                    if (action.index !== undefined && action.index > -1) {
                        _.pullAt(draft, [action.index])
                    }
                })
            }
            case 'UPDATE_FORM':{
                return produce(state, draft => {
                    const found = _.find(draft, (o) => {
                        return o.id === action.id
                    })

                    if (found) {
                        const inputToUpdate = _.find(found.inputs, (o) => {
                            return o.answerValue === action.columnOrder
                        })

                        if (inputToUpdate) {
                            inputToUpdate.userTypedAnswer = action.value
                        }
                    }
                })
            }
            case 'UPDATE_ELEMENT':{
                return produce(state, draft => {
                    const found = _.find(draft, (o) => {
                        return o.id === action.id
                    })

                    if (found) {
                        found.inputs = action.value
                    }
                })
            }
            default:
                return state
        }
    }, [])

    const addForm = () => {
        formsDispatch({ type: 'ADD_FORM' })
    }

    useEffect(() => {
        const data = _.cloneDeep(questionInterface.currentReasonWithMeResponse.reasoningData)

        const previousQuestionData1 = data.previousQuestionData
        const previousQuestionData2 = data.question?.previousQuestionData

        let combinedHeaders: QuestionHeader[] = []

        if (
            (previousQuestionData2?.questionType === 'open-list' ||
            // if there's nothing.
            !previousQuestionData2?.questionType)
        ) {
            const questionAnswers: OpenListPreviousQuestions[] = previousQuestionData1
                ?.questionAnswers?.answers || []

            combinedHeaders = [
                ..._.flatMap(questionAnswers?.[0]?.inputs || [], (p) => {
                    return p.options
                }),
                ..._.map(data.question?.questionAnswers, (o) => {
                    return {
                        answerValue: (Number(o.answerValue) +
                        ((questionAnswers?.[0]?.inputs?.length) || 0)) as string | number,
                        answerName: o.answerName || '',
                        answerType: o.answerType || '',
                        answerEditable: o.answerEditable || true
                    }
                })
            ]

            console.log('combined headers: ', combinedHeaders)

            const multiListIndex = combinedHeaders
                .findIndex(item => item.answerType === 'multi-list')

            if (multiListIndex !== -1) {
                // step 1. make sure that multi-list is the only column in combinedHeaders.
                combinedHeaders = [
                    combinedHeaders[multiListIndex]
                ]

                // OK THAT'S IT.
            }

            const inputListSplitIndex = combinedHeaders
                .findIndex(item => item.answerType === 'input-list-split')

            if (inputListSplitIndex !== -1) {
            // STEP 2: Find indices of input and input-list BEFORE input-list-split

                const inputListObj = combinedHeaders.find(o => o.answerType === 'input-list')

                combinedHeaders.slice(0, inputListSplitIndex)
                    .filter(item => item.answerType === 'input' || item.answerType === 'input-list')

                // STEP 3: Remove input-list-split and input-list
                combinedHeaders.splice(inputListSplitIndex, 1) // Remove input-list-split

                // Filter out input-list before split
                const inputListIndices = combinedHeaders.map((item, index) => ({
                    index,
                    answerType: item.answerType
                }))
                    .filter(item => item.answerType === 'input-list')

                inputListIndices
                    .forEach(item => combinedHeaders.splice(item.index, 1)) // Remove input-list

                // STEP 4: Add new input header AFTER the first input found
                const firstInputIndex = combinedHeaders
                    .findIndex(item => item.answerType === 'input')
                const firstInput = combinedHeaders.find(item => item.answerType === 'input')

                if (firstInputIndex !== -1 && inputListObj && firstInput) {
                    combinedHeaders.splice(firstInputIndex + 1, 0, {
                        answerValue: firstInputIndex + 1,
                        answerName: inputListObj.answerName,
                        answerType: firstInput.answerType,
                        answerEditable: firstInput.answerEditable
                    })
                }
            }

            combinedHeaders.sort((a, b) => {
                const valueA = typeof a.answerValue === 'string'
                    ? parseInt(a.answerValue, 10)
                    : a.answerValue
                const valueB = typeof b.answerValue === 'string'
                    ? parseInt(b.answerValue, 10)
                    : b.answerValue

                return valueA - valueB
            })

            // Step 2: Reassign sequential numbers to answerValue
            combinedHeaders.forEach((item, index) => {
                item.answerValue = index + 1
            })

            console.log(combinedHeaders)
        } else if (
            previousQuestionData2?.questionType === 'multi-question'
        ) {
            const filteredAnswers = (data
                .previousQuestionData?.questionAnswers?.answers || []
            )
                .flatMap((item: OpenMultiQuestionOption) => {
                    const arr = item?.answers || []
                    return arr.flatMap(av =>
                        av.question.answerQuestions?.filter(avItem =>
                            avItem.questionIdentifier === previousQuestionData1
                                ?.userQuestionIdentifier
                        )
                    )
                })

            // applicable if the question identifier has the answer type input-list
            const getFirst = (filteredAnswers
                ?.[0]?.questionAnswers[0])

            const getPreviousHeader: QuestionHeader = {
                answerValue: getFirst?.answerValue as any,
                answerName: getFirst?.answerName || '',
                answerType: getFirst?.answerType || '',
                answerEditable: getFirst?.answerEditable || true
            }

            combinedHeaders = [
                getPreviousHeader,
                ..._.map(data.question?.questionAnswers, (o) => {
                    return {
                        answerValue: (Number(o.answerValue) +
                        ((data.previousQuestionData?.questionAnswers?.answers
                            ?.[0]?.inputs?.length) || 0)) as string | number,
                        answerName: o.answerName || '',
                        answerType: o.answerType || '',
                        answerEditable: o.answerEditable || true
                    }
                })
            ]
        }

        combinedHeaders.sort((a, b) => {
            const valueA = typeof a.answerValue === 'string'
                ? parseInt(a.answerValue, 10)
                : a.answerValue
            const valueB = typeof b.answerValue === 'string'
                ? parseInt(b.answerValue, 10)
                : b.answerValue

            return valueA - valueB
        })

        // Step 2: Reassign sequential numbers to answerValue
        combinedHeaders.forEach((item, index) => {
            item.answerValue = index + 1
        })

        setQuestionHeaders(combinedHeaders)
    }, [questionInterface.currentReasonWithMeResponse.reasoningData])

    useEffect(() => {
        const data = _.cloneDeep(questionInterface.currentReasonWithMeResponse.reasoningData)

        const previousQuestionData1 = data.previousQuestionData
        const previousQuestionData2 = data.question?.previousQuestionData

        const inputListSplitIndex = data.question?.questionAnswers.findIndex(
            item => item.answerType === 'input-list-split'
        )

        const multiListIndex = data.question?.questionAnswers.findIndex(
            item => item.answerType === 'multi-list'
        )

        if (previousQuestionData2?.questionType === 'open-list') {
            // this could be the case IF multi-list is not defined here either.
            if (multiListIndex !== -1) {
                const mappedValues = _.compact(_.flatMap(
                    (previousQuestionData1?.questionAnswers?.answers ||
                        []) as OpenListPreviousQuestions[],
                    (obj) => {
                        const inputList = obj.inputs.find(input => {
                            return input.options.answerType === 'input-list'
                        })

                        if (inputList && _.isArray(inputList.answerValue)) {
                            const restInputs = obj.inputs.filter(input => input !== inputList)
                            const renderButtons = restInputs.every(input => input
                                .options.answerType === 'input')

                            if (renderButtons) {
                                const buttonContent = inputList.answerValue.flatMap((
                                    answer, answerIndex
                                ) => {
                                    const buttonText = [
                                        ...restInputs.map(
                                            input => input.answerValue
                                        ), answer
                                    ].join(', ')

                                    return {
                                        answerValue: 0,
                                        answerName: buttonText,
                                        answerType: 'multi-list',
                                        answerEditable: true,
                                        userTypedAnswer: false
                                    }
                                })

                                return buttonContent
                            }

                            return null
                        }

                        return null // If conditions aren't met, return null to skip rendering
                    }
                ))

                // console.log('mapped values: ', mappedValues)

                setPreviousAnswers([{
                    id: uuidV4(),
                    inputs: mappedValues
                }])
            } else if (inputListSplitIndex !== -1) {
                const transformedData: OpenListValues[] = []

                previousQuestionData1?.questionAnswers
                    ?.answers?.forEach((item: OpenListPreviousQuestions) => {
                        const { inputs } = item
                        const inputListAnswers = inputs
                            .find(input => input.options.answerType === 'input-list')

                        if (inputListAnswers && Array.isArray(inputListAnswers.answerValue)) {
                            const foundHeader1 = _.find(questionHeaders, (o) => {
                                return o.answerName === inputs[0]?.options.answerName
                            })

                            const foundHeader2 = _.find(questionHeaders, (o) => {
                                return o.answerName === inputs[1].options.answerName
                            })

                            if (foundHeader1 && foundHeader2) {
                                inputListAnswers.answerValue.forEach(answer => {
                                    const newItem = {
                                        id: uuidV4(),
                                        inputs: [
                                            {
                                                answerValue: foundHeader1.answerValue,
                                                answerName: foundHeader1.answerName,
                                                answerType: 'input',
                                                answerEditable: foundHeader1.answerEditable,
                                                userTypedAnswer: inputs[0]?.answerValue
                                            },
                                            {
                                                answerValue: foundHeader2.answerValue,
                                                answerName: foundHeader2.answerName,
                                                answerType: 'input',
                                                answerEditable: foundHeader2.answerEditable,
                                                userTypedAnswer: answer
                                            }
                                        ]
                                    }
                                    transformedData.push(newItem)
                                })
                            }
                        }
                    })

                setPreviousAnswers(transformedData)
            } else {
                let arr: OpenListPreviousQuestions[] = previousQuestionData1
                    ?.questionAnswers?.answers || []
                // before mapping, filter out values based on onlyIf object.
                const onlyIf = previousQuestionData2.onlyIf

                const firstElement: OpenListPreviousQuestions = previousQuestionData1
                    ?.questionAnswers
                    ?.answers?.[0]

                const foundIndicator = _.find(
                    firstElement?.inputs,
                    (o) => {
                        const options = o.options
                        return options.answerValue === onlyIf?.answerValue
                    }
                )

                console.log('only if', onlyIf)
                console.log('found indicator: ', foundIndicator)

                if (foundIndicator) {
                    arr = arr.filter((outer) => {
                        // get inputs field.
                        const foundAgain = _.find(
                            outer.inputs,
                            (inner) => {
                                const options = inner.options
                                return options.answerValue === onlyIf?.answerValue
                            }
                        )

                        // console.log('id: ', outer.id)
                        console.log('foundAgain: ', foundAgain)

                        if (foundAgain) {
                            // check answertype through if statments.
                            if (foundAgain.options.answerType === 'radio') {
                                return foundAgain.answerValue === onlyIf?.answerIs
                            } else if (foundAgain.options.answerType === 'checkbox') {
                                return foundAgain.answerValue === onlyIf?.answerIs
                            }
                        }

                        return true
                    })
                }

                // the previous question data is of answerType input.
                // and the inputs array is one element.
                // things can change.
                const mappedValues = _.map(
                    arr, (obj) => {
                        // go to the first element and get inputs length.

                        const inputs = []

                        for (let i = 0; i < obj.inputs.length; i++) {
                            inputs.push({
                                answerValue: obj.inputs[i]?.options.answerValue || '',
                                answerName: obj.inputs[i]?.options.answerName || '',
                                answerType: obj.inputs[i]?.options.answerType || '',
                                answerEditable: obj.inputs[i]?.options.answerEditable || '',
                                userTypedAnswer: obj.inputs[i]?.answerValue || ''
                            })
                        }

                        return {
                            id: obj.id,
                            // inputs: [{
                            //     answerValue: obj.inputs[0]?.options.answerValue || '',
                            //     answerName: obj.inputs[0]?.options.answerName || '',
                            //     answerType: obj.inputs[0]?.options.answerType || '',
                            //     answerEditable: obj.inputs[0]?.options.answerEditable || '',
                            //     userTypedAnswer: obj.inputs[0]?.answerValue || ''
                            // }]
                            inputs
                        }
                    }
                )

                setPreviousAnswers(mappedValues)
            }
        } else if (
            previousQuestionData2?.questionType === 'multi-question'
        ) {
            const filteredAnswers = (data
                .previousQuestionData?.questionAnswers?.answers || []
            )
                .flatMap((item: OpenMultiQuestionOption) => {
                    const arr = item?.answers || []
                    return arr.filter(av => {
                        return av.question.answerQuestions?.filter(avItem =>
                            avItem.questionIdentifier === previousQuestionData1
                                ?.userQuestionIdentifier
                        ).length
                    }

                    )
                })

            // console.log('filtered answers: ', filteredAnswers)

            const result: OpenListValues[] = _.flatMap(filteredAnswers, o => {
                return o.answer.flatMap(p => {
                    return p.actualValue
                })
            })

            // console.log('result: ', result)

            setPreviousAnswers(result)
        }
    }, [questionHeaders])

    useEffect(() => {
        console.log('previous answers:', previousAnswers)
        let defaultAssignment: OpenListValues[] = []

        // header has multi-list.
        const multiListIndex = questionHeaders
            .findIndex(item => item.answerType === 'multi-list')

        if (multiListIndex !== -1) {
            // don't add a default blank row
            defaultAssignment = previousAnswers
        } else {
            defaultAssignment =
            [
                ..._.map(previousAnswers, outer => {
                    const inputs = outer.inputs
                    return {
                        id: outer.id,
                        inputs: _.map(questionHeaders, (inner, index) => {
                            const answerType = inner.answerType as AnswerTypes

                            const answerValue = inner.answerValue as number

                            let userTypedAnswer: string | string[] | boolean = ''

                            if (inputs?.[index]?.userTypedAnswer) {
                                userTypedAnswer = inputs?.[index]?.userTypedAnswer
                            } else {
                                if (answerType === 'input') {
                                    userTypedAnswer = ''
                                } else if (answerType === 'radio') {
                                    userTypedAnswer = false
                                } else if (answerType === 'checkbox') {
                                    userTypedAnswer = false
                                } else if (answerType === 'input-list') {
                                    userTypedAnswer = ['']
                                }
                            }

                            return {
                                answerValue,
                                answerName: inner.answerName || '',
                                answerType,
                                answerEditable: true,
                                userTypedAnswer
                            }
                        })
                    }
                }),
                {
                    id: uuidV4(),
                    inputs: _.map(questionHeaders, (o) => {
                        const answerType = o.answerType as AnswerTypes

                        let userTypedAnswer: string | string[] | boolean = ''

                        if (answerType === 'input') {
                            userTypedAnswer = ''
                        } else if (answerType === 'radio') {
                            userTypedAnswer = false
                        } else if (answerType === 'checkbox') {
                            userTypedAnswer = false
                        } else if (answerType === 'input-list') {
                            userTypedAnswer = ['']
                        }

                        const answerValue = o.answerValue as number
                        return {
                            answerValue,
                            answerName: o.answerName || '',
                            answerType,
                            answerEditable: o.answerEditable || true,
                            userTypedAnswer
                        }
                    })
                }

            ]
        }

        // console.log('assigning initial values: ', defaultAssignment)

        formsDispatch({
            type: 'SET_INITIAL_VALUES',
            arr: defaultAssignment
        })
    }, [questionHeaders, previousAnswers])

    useEffect(() => {
        // console.log(formsState)
        const data = _.cloneDeep(questionInterface.currentReasonWithMeResponse.reasoningData)

        // currently {id: string, inputs: []} array. now it should change.
        const newFormat:OpenListPreviousQuestions[] = _.map(formsState, (o) => {
            return {
                id: o.id,
                inputs: _.map(o.inputs, o => {
                    const { userTypedAnswer, ...rest } = o

                    return {
                        options: rest,
                        answerValue: userTypedAnswer
                    }
                })
            }
        })

        const result = {
            ...(data.question
                ?.previousQuestionData?.questionUseAnswerFrom && {
                questionUseAnswerFrom: data.question
                    ?.previousQuestionData?.questionUseAnswerFrom
            }),
            ...(data
                ?.previousQuestionData?.userQuestionIdentifier && {
                userQuestionIdentifier: data
                    ?.previousQuestionData?.userQuestionIdentifier
            }),
            answers: newFormat
        }

        if (componentDispatch !== undefined) {
            componentDispatch({
                type: 'SET_ANSWER_VALUE',
                value: result
            })
        } else {
            dispatch(setAnswerValue(result))
        }
    }, [formsState])

    useEffect(() => {
        if (enableTruncation) {
            // update the formState.

            // enableTruncation is true, then filter the formState before rendering.
            let filtered: OpenListValues[] = []

            if (enableTruncation) {
                _.forEach(formsState, (o) => {
                // if there is at least one truthy userTypedAnswer, add to record.
                    const addToList = _.some(o.inputs, (p) => {
                        const userTypedAnswer = p.userTypedAnswer

                        if (typeof userTypedAnswer === 'string') {
                        // checks if the input is lengthy
                            return userTypedAnswer.length > 0
                        } else if (_.isArray(userTypedAnswer)) {
                        // checks if the input type list has more than one truthy record.
                            return _.some(
                                userTypedAnswer, (q) => {
                                    return q.length > 0
                                }
                            )
                        } else if (typeof userTypedAnswer === 'boolean') {
                        // this is included too because we'll have to treat it as
                        // not interacted with.
                            return userTypedAnswer === true
                        } else {
                            return false
                        }
                    })

                    if (addToList === true) {
                        filtered.push(
                            o
                        )
                    }
                })
            } else {
                filtered = formsState
            }

            formsDispatch({
                type: 'SET_INITIAL_VALUES',
                arr: filtered
            })

            // then set truncate to false again.
            setEnableTruncation(false)
        }
    }, [enableTruncation])

    const input = useMemo(() => {
        const data = questionInterface.currentReasonWithMeResponse.reasoningData
        // const answerValue = questionInterface.currentReasonWithMeResponse.answerValue

        // header has multi-list.
        const multiListIndex = questionHeaders
            .findIndex(item => item.answerType === 'multi-list')

        const toRender = formsState.map((form, outerIndex) => {
            const sorted = [...form.inputs]
                .sort((a, b) => {
                    const valueA = Number(a.answerValue || 0)
                    const valueB = Number(b.answerValue || 0)
                    return valueA - valueB
                })

            const hasPrevious = _.find(previousAnswers, (toCheck) => {
                // we gotta check if this row's id matches with the one here.
                return toCheck.id === form.id
            })

            // console.log('hasPrevious: ', hasPrevious)

            const rowHasInputList = _.includes(
                _.map(form.inputs, (o) => {
                    return o.answerType
                }), 'input-list'
            )

            const inputs = _.map(sorted,
                (formInput, innerIndex) => {
                    const findPreviousInput = _.find(hasPrevious?.inputs, (o) => {
                        return o.answerName === formInput.answerName
                    })

                    const answerType = formInput.answerType as AnswerTypes

                    // what will be rendered will be different

                    let input = <div></div>

                    if (answerType === 'input') {
                        input = <Open
                            answerType={answerType} outerIndex={outerIndex} innerIndex={innerIndex}
                            formInput={formInput} findPreviousInput={findPreviousInput}
                            formsDispatch={formsDispatch} form={form}
                        />
                    } else if (answerType === 'radio') {
                        input = <Radio
                            inputs={sorted}
                            answerType={answerType} outerIndex={outerIndex} innerIndex={innerIndex}
                            formInput={formInput} findPreviousInput={findPreviousInput}
                            formsDispatch={formsDispatch} form={form}
                        />
                    } else if (answerType === 'checkbox') {
                        input = <Checkbox
                            answerType={answerType} outerIndex={outerIndex} innerIndex={innerIndex}
                            formInput={formInput} findPreviousInput={findPreviousInput}
                            formsDispatch={formsDispatch} form={form}
                        />
                    } else if (answerType === 'input-list') {
                        input = <InputList
                            answerType={answerType} outerIndex={outerIndex} innerIndex={innerIndex}
                            formInput={formInput} findPreviousInput={findPreviousInput}
                            formsDispatch={formsDispatch} form={form}
                        />
                    }

                    return <td key={['table-cell', outerIndex, innerIndex].join('-')}
                        className={[
                            rowHasInputList ? '' : 'align-middle',
                            'text-center'
                        ].join(' ')}>
                        {input}
                    </td>
                })

            return <tr key={['table-row', outerIndex].join('-')}>
                {inputs}
                {
                    formsState.length > 1
                        ? <td className={[
                            rowHasInputList ? 'pt-4' : 'align-middle',
                            'text-center ',
                            hasPrevious ? 'disabled' : 'cursor-pointer'
                        ].join(' ')
                        }
                        >
                            <div className={'question-plussign ms-auto'}
                                onClick={() => {
                                    formsDispatch({
                                        type: 'DELETE_FORM',
                                        index: outerIndex
                                    })
                                }}>
                                <i className={`fa-light
                            ${ hasPrevious ? 'fa-trash-slash' : 'fa-trash px-1' }`}></i>
                            </div>

                        </td>
                        : ''
                }
            </tr>
        })

        return <div className={'table-responsive'}>

            {!(multiListIndex !== -1) && (
                <table className={'table table-borderless'}>
                    <thead>
                        <tr>
                            {
                                _.map(questionHeaders, (obj, i) => {
                                    return <th className={'align-middle text-center'} style={{
                                        minWidth: obj.answerType === 'input'
                                            ? 150
                                            : obj.answerType === 'input-list'
                                                ? 150
                                                : 'initial',
                                        whiteSpace: obj.answerType === 'input'
                                            ? 'nowrap'
                                            : obj.answerType === 'input-list'
                                                ? 'nowrap'
                                                : 'initial'
                                    }}
                                    key={['th', i].join('-')
                                    }>
                                        {obj.answerName}
                                    </th>
                                })
                            }
                            {
                                formsState.length > 1
                                    ? (
                                        <th className={'align-middle text-center'}>
                                            {strings.reason_with_me?.text['open-list'].action}</th>
                                    )
                                    : ''
                            }

                        </tr>
                    </thead>
                    <tbody>
                        {toRender}
                        {
                            (data.question?.questionAnswersAllowed === 0) ||
                            (formsState.length < (data.question?.questionAnswersAllowed || 1))
                                ? <tr>
                                    <td className={[
                                        'align-middle text-end'
                                    ].join(' ') }
                                    colSpan={2 + (questionHeaders.length || 0) +
                                (data.previousQuestionData
                                    ?.questionAnswers?.answers?.length
                                    ? 1
                                    : 0) }>
                                        <button type={'button'} className={[
                                            'px-5 btn btn-dark mx-auto btn-rounded'
                                        ].join(' ')}
                                        // disabled={
                                        //     formState.length >= (data.question
                                        //         ?.questionAnswersAllowed || 0)
                                        // }
                                        onClick={addForm}>
                                            {strings.app?.text.add}
                                        </button>
                                    </td>
                                </tr>
                                : <></>
                        }

                    </tbody>
                </table>
            )}

            {
                multiListIndex !== -1 && <MultiList
                    questionInterface={questionInterface}
                    formsDispatch={formsDispatch} form={formsState[0]}
                />
            }

        </div>

        // turning this into undefined cause the removeChild node crash
    }, [questionInterface])

    return <div className={'question-open-list'}>
        {input}
    </div>
}

export default OpenList
