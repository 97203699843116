
import { MODULE_TABLE } from '@app/app.config'
import {
    ACTION_MUTATION_PROMISE,
    MOBILE_RESPONSIVE_LIMIT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { getErrorText, smartSearch } from '@app/app.method'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import { push } from '@lagunovsky/redux-react-router'
import { useGetLibraryMutation } from '@library/api'
import { IDS, MODULE_VERSION } from '@library/constants'
import { Content, GroupedArticles } from '@library/type'
import produce from 'immer'
import _ from 'lodash'
import { useEffect, useMemo, useReducer, useState } from 'react'

import { useMediaQuery } from 'react-responsive'
import { toast } from 'react-toastify'

import {
    CardImageWrapper,
    HeaderGradient,
    HeaderImage
} from '@stylesheet/globalStyles/group/endUser/recoveryPlan/Components'

const Library = () => {
    const dispatch = useAppDispatch()
    const token = useAppSelector(selectToken)

    const validateRoute = useValidateRoute()
    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const initializeSidebarVisibility = useInitializeSidebarVisibility()

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const [getLibrary, getLibraryMutation] = useGetLibraryMutation()
    const [isSaved, setIsSaved] = useState<boolean>(false)

    useEffect(() => {
        initializeSidebarVisibility(true)
    }, [])

    const [librarySearchState, librarySearchDispatch] = useReducer(
        (
            state: { search: string, grouped: GroupedArticles },
            action: | { type: 'UPDATE_SEARCH', value: string } | { type:
                'UPDATE_GROUPED', value: GroupedArticles } |
                { type: 'UPDATE_COLLAPSIBLE', value: {
                    key: string,
                    value: boolean
                }}
        ) => {
            switch (action.type) {
                case 'UPDATE_SEARCH': {
                    return produce(state, draft => {
                        draft.search = action.value
                    })
                } case 'UPDATE_GROUPED': {
                    return produce(state, draft => {
                        draft.grouped = action.value
                    })
                } case 'UPDATE_COLLAPSIBLE': {
                    return produce(state, draft => {
                        draft.grouped[action.value.key].collapsible = action.value.value
                    })
                }
            }
        }, {
            search: '',
            grouped: {}
        }
    )

    const unsubscribeGetLibrary = () => {
        const unsubscribeMutation = getLibrary({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** create fetch data function */
    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetLibrary()

        let getLibraryPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.library.moduleName,
                        MODULE_TABLE.library.apiPaths.getLibrary.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getLibraryPromise = getLibrary({
                            authToken: newToken.value

                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getLibraryPromise && getLibraryPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData(token)
    }, [token.id, token.valid])

    useEffect(() => {
        if (getLibraryMutation.error) {
            const message = getErrorText(getLibraryMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getLibraryMutation.error])

    const groupArticlesByCategory = (data: Content[]): GroupedArticles => {
        const grouped: GroupedArticles = {}

        _.forEach(data, (article) => {
            const categories = article.contentCategory
            if (!categories || categories.length === 0) {
                // If the article has no contentCategory, add it to "No Name" category
                const categoryName = strings.library?.text.uncategorized || 'null'
                if (!grouped[categoryName]) {
                    grouped[categoryName] = {
                        collapsible: true,
                        articles: [article]
                    }
                } else {
                    grouped[categoryName].articles.push(article)
                }
            } else {
                // Otherwise, handle all the categories of the article
                categories.forEach((category) => {
                    const categoryName = category.labelName

                    if (!categoryName || _.lowerCase(token.details.locale)
                        .replace(' ', '-') !== category.locale) {
                        // If the article has no contentCategory, add it to "No Name" category
                        const categoryName = strings.library?.text.uncategorized || 'null'
                        if (!grouped[categoryName]) {
                            grouped[categoryName] = {
                                collapsible: true,
                                articles: [article]
                            }
                        } else {
                            grouped[categoryName].articles.push(article)
                        }
                    } else {
                        if (!grouped[categoryName]) {
                            grouped[categoryName] = {
                                collapsible: true,
                                articles: [article]
                            }
                        } else {
                            grouped[categoryName].articles.push(article)
                        }
                    }
                })
            }
        })

        // Sort the categories and move "No Name" category to the last position
        const sortedGrouped: GroupedArticles = _.fromPairs(
            _.sortBy(_.toPairs(grouped), ([categoryName]) => {
                return categoryName === (strings.library?.text.uncategorized || 'null')
                    ? 1
                    : 0
            })
        )

        return sortedGrouped
    }

    useEffect(() => {
        let filteredData = smartSearch(getLibraryMutation.data?.data.contentData || [],
            [], librarySearchState.search
        ) as Content[]

        // then filter again based on isSaved. if isSaved is true, filter by isSaved.
        if (isSaved) {
            filteredData = _.filter(filteredData, ({ isSaved }) => isSaved === true)
        }

        // step 1: group first.
        const grouped = groupArticlesByCategory(filteredData)

        _.forEach(grouped, (group) => {
            group.articles = _.sortBy(group.articles, [(obj) => {
                const matchNumber : RegExpMatchArray | null | undefined =
                obj.contentName?.match(/\d+/)
                if (matchNumber) {
                    return parseInt(matchNumber[0])
                } else {
                    return 0
                }
            }])
        })

        librarySearchDispatch({
            type: 'UPDATE_GROUPED',
            value: grouped
        })
    }, [getLibraryMutation.data?.data.contentData, librarySearchState.search, isSaved])

    const menu = useMemo(() => {
        // Sort the activities by their order.
        // Sorting articles within each category based on the articleId

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <div className={'spinner-container'}>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{
                        strings.app?.text.loading || ''
                    }</span>
                </div>
            </small>
        )

        const content = _.map(
            _.entries(librarySearchState.grouped),
            ([categoryName, categoryArticles], index) => {
                const mappedResult = _.map(categoryArticles.articles, (obj) => {
                    // Generate a unique key for each group.
                    const key = categoryName

                    const colImageClassName = 'col-auto'

                    const colContentClassName = 'col'

                    let imageWrapperWidth = '150px'

                    let imageWrapperHeight = 120

                    if (isMobile) {
                        imageWrapperWidth = '65px'
                        imageWrapperHeight = 81
                    }

                    const cardTitle = <span className={
                        [
                            'title',
                            'text-truncate-2 w-100',
                            'mb-2'
                        ].join(' ')
                    }>
                        {obj?.contentName}
                    </span>

                    const cardBody = <div key={key}
                        className={'card-body position-relative'}
                        onClick={() => {
                            if (obj.contentId) {
                                // now dispatch to another page.
                                const isValid = validateRoute(
                                    activeModules.arr,
                                    MODULE_TABLE.library.moduleName,
                                    MODULE_TABLE.library.routes.article,
                                    true
                                )
                                if (isValid) {
                                    // now push but replace :personalCareplanId with something else
                                    dispatch(push(
                                        _.replace(isValid.route,
                                            ':articleId', obj.articleId)
                                    ))
                                }
                            } else {
                                //
                            }
                        }}>
                        <div className={[
                            'row', isMobile ? 'g-3' : ''
                        ].join(' ')}>
                            <div className={colImageClassName}>
                                <CardImageWrapper className={''}

                                    url={obj.images.thumbnail || ''}
                                    style={{
                                        width: imageWrapperWidth,
                                        height: imageWrapperHeight
                                    }}
                                >
                                </CardImageWrapper>
                            </div>
                            <div className={[
                                colContentClassName,
                                !isMobile ? 'mb-3 mt-3' : ''
                            ].join(' ')}>
                                <div className={'row'}>
                                    <div className={'col-auto'}>
                                        {cardTitle}
                                    </div>
                                </div>
                                <div className={
                                    'align-items-center justify-content-between row'
                                }>
                                    <div className={'col-auto'}>
                                        {/* change color based on type */}
                                        <small className={[
                                            'fw-bold content-type',
                                            'content'
                                        ].join(' ')}>
                                            {
                                                strings.careplanPatient
                                                    ?.text.careplanMenu.filters.content
                                            }
                                        </small>
                                    </div>
                                    <div className={'col-4'}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    return <div className={'col'}>
                        <div className={'card careplan-card'}>
                            {cardBody}
                        </div>
                    </div>
                })

                return <div className={[
                    index ? 'mt-5' : '',
                    'careplan-group-container'
                ].join(' ')} key={categoryName}>
                    <div className={'row justify-content-between align-items-center mb-3'}>
                        <div className={'col-auto'}>
                            <span className={[
                                'd-inline-block mb-0 title'
                            ].join(' ')}>
                                {categoryName}
                            </span>
                        </div>
                        <div className={'col-auto'}>
                            <button
                                type={'button'}
                                className={[
                                    'btn btn-round btn-rounded me-3'
                                ].join('')}
                                onClick={() => {
                                    librarySearchDispatch({
                                        type: 'UPDATE_COLLAPSIBLE',
                                        value: {
                                            value: !librarySearchState
                                                .grouped[categoryName].collapsible,
                                            key: categoryName
                                        }
                                    })
                                }}
                            >
                                <i
                                    className={[
                                        'fa-light',
                                        librarySearchState
                                            .grouped[categoryName].collapsible
                                            ? 'fa-chevron-up'
                                            : ' fa-chevron-down'
                                    ].join(' ')}
                                    aria-hidden={'true'}>
                                </i>
                            </button>
                        </div>
                    </div>
                    {librarySearchState
                        .grouped[categoryName].collapsible
                        ? <div className={['card-grid row row-cols-1 row-cols-md-2',
                            isMobile ? 'g-3' : 'g-4'].join(' ')}>
                            {mappedResult}
                        </div>
                        : ''}
                </div>
            })

        // Render the grouped activities with their corresponding order number.
        return (
            getLibraryMutation.isLoading
                ? LoadingContent
                : getLibraryMutation.isSuccess
                    ? content
                    : JSON.stringify(getLibraryMutation.error)
        )
    }, [getLibraryMutation, strings, librarySearchState, isSaved, isMobile])

    const columnOne = useMemo(() => {
        const filteredData = smartSearch(getLibraryMutation.data?.data.contentData || [],
            [], librarySearchState.search
        ) as Content[]

        const isSavedData = _.filter(filteredData, ({ isSaved }) => isSaved === true)
        const notIsSavedData = _.filter(filteredData, ({ isSaved }) => isSaved === false)

        return <div>
            <span className={[
                'my-3 d-inline-block',
                isMobile ? 'h3' : 'h1'
            ].join(' ')}>
                {strings.careplanPatient?.text.navigation.library}
            </span>

            <div className={'justify-content-between mb-3 row'}>
                <div className={'col-auto mb-md-0'}>
                    <div className={'form-group'}>
                        <div className={'form-control-wrapper'}>
                            <input type={'search'}
                                className={'form-control form-search'}
                                placeholder={strings.app?.text.search.text}
                                value={librarySearchState.search}
                                onChange={(e) => {
                                    librarySearchDispatch({
                                        type: 'UPDATE_SEARCH',
                                        value: e.target.value
                                    })
                                }}
                                id={IDS.LIBRARY.SEARCH} />
                            <label
                                className={'form-floaticon'}
                                htmlFor={IDS.LIBRARY.SEARCH}
                            >
                                <div
                                    className={'icon '}>
                                    <i className={'fa-light fa-search'} aria-hidden={'true'}></i>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div className={[
                'py-3 row row-cols-1 row-cols-xl-2',
                !isMobile ? 'mb-5' : 'mb-3'
            ].join(' ')}>
                <div className={'col'}
                    onClick={() => { setIsSaved(false) }}>
                    <div className={[
                        'border-top border-bottom row',
                        isMobile ? 'py-1' : ' py-3',
                        'justify-content-between align-items-center mx-2'
                    ].join(' ')}>
                        <div className={'col-auto'}>
                            <div className={'row align-items-center clickable'}>
                                <div className={'col-auto'}>
                                    {
                                        isMobile
                                            ? <h6 className={'d-inline-block mb-0'}>
                                                {strings.careplanPatient?.text.selection_for_you}
                                            </h6>
                                            : <h5 className={'d-inline-block mb-0'}>
                                                {strings.careplanPatient?.text.selection_for_you}
                                            </h5>
                                    }
                                </div>
                                <div className={'col-auto ps-0'}>
                                    {/* a circle div and then a text inside */}
                                    <div className={'group-count'}>
                                        {
                                            isMobile
                                                ? <h6 className={'mb-0'}>
                                                    {notIsSavedData.length
                                                        ? notIsSavedData.length
                                                        : '0'}
                                                </h6>
                                                : <h5 className={'mb-0'}>
                                                    {notIsSavedData.length
                                                        ? notIsSavedData.length
                                                        : '0'}
                                                </h5>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'col-auto ps-0'}>
                            <a className={'nav-link clickable'} onClick={(e) => {
                                e.preventDefault()
                            }}>
                                <i className={'fa-light fa-chevron-right'} aria-hidden={'true'}></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className={'col '}
                    onClick={() => { setIsSaved(true) }}>
                    <div className={[
                        'border-top border-bottom row',
                        isMobile ? 'py-1' : ' py-3',
                        'justify-content-between align-items-center mx-2'
                    ].join(' ')}>
                        <div className={'col-auto'}>
                            <div className={'row align-items-center clickable'}>
                                <div className={'col-auto'}>
                                    {
                                        isMobile
                                            ? <h6 className={'d-inline-block mb-0'}>
                                                {strings.careplanPatient?.text.saved_articles_long}
                                            </h6>
                                            : <h5 className={'d-inline-block mb-0'}>
                                                {strings.careplanPatient?.text.saved_articles_long}
                                            </h5>
                                    }
                                </div>
                                <div className={'col-auto ps-0'}>
                                    {/* a circle div and then a text inside */}
                                    <div className={'group-count'}>
                                        {
                                            isMobile
                                                ? <h6 className={'mb-0'}>
                                                    {isSavedData.length
                                                        ? isSavedData.length
                                                        : '0'}
                                                </h6>
                                                : <h5 className={'mb-0'}>
                                                    {isSavedData.length
                                                        ? isSavedData.length
                                                        : '0'}
                                                </h5>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'col-auto ps-0'}>
                            <a className={'nav-link clickable'} onClick={(e) => {
                                e.preventDefault()
                            }}>
                                <i className={'fa-light fa-chevron-right'} aria-hidden={'true'}></i>
                            </a>
                        </div>
                    </div>
                </div>

            </div>

            <div className={''}>
                <span className={[
                    'my-3 d-inline-block grey-text',
                    isMobile ? 'h5' : 'h5'
                ].join(' ')}>
                    {strings.library?.text.categories}
                </span>
                <div className={'row'}>
                    <div className={[
                        'col-12 mx-auto'
                    ].join(' ')}>
                        {menu}
                    </div>
                </div>
            </div>
        </div>
    }, undefined)

    /** components that show up in mobile header only */
    const mobileHeader = <div className={'container-fluid header text-center'}>
        <div className={'align-items-center h-100 justify-content-between row'}>
            <div className={'col-auto'}>
                {/* <h4 className={'mb-0 '} onClick={() => {
                    dispatch(toggleMenuTopSidebarMobileBar(
                        !showMenuBar.mobile.top.sidebar
                    ))
                }}>
                    <i
                        className={ [
                            'fa-light',
                            showMenuBar.mobile.top.sidebar ? 'fa-bars-staggered' : 'fa-bars'
                        ].join(' ')}
                    >
                    </i>
                </h4> */}
            </div>
            <div className={'col-auto'}>
                {/* image of logo goes here. fixed width but height can change whatever */}
                <img src={'/images_new/header/logo.svg'} />
            </div>
            <div className={'col-auto'}>

            </div>
        </div>

    </div>

    const desktopResult = <>
        {/* put header image here */}
        <HeaderImage url={'/images_new/header/1.png'} />
        <HeaderGradient />

        <div className={'main-content'}>
            {/* container with a col width and then set to center */}
            <div className={'container mx-auto'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-auto careplan-cards-container'}>
                        {columnOne}
                    </div>
                </div>
            </div>
        </div>
        <div className={
            'position-fixed bottom-0 end-0 py-2 pe-5 fs-label fw-light'
        }>
            {MODULE_VERSION}
        </div>
    </>

    const mobileResult = <>
        {mobileHeader}
        <HeaderImage url={'/images_new/header/1.png'} />
        <HeaderGradient />
        <div className={'container-fluid main-content px-6'}>
            <div className={'pb-5'}>{columnOne}</div>
        </div>
    </>

    return <div className={[
        'library-page', !isMobile ? 'position-relative' : ''
    ].join(' ')}>

        {
            isMobile ? mobileResult : desktopResult
        }

    </div>
}

export default Library
